const server = "https://doon-api-f01d22588e89.herokuapp.com";
export const environment = {
  production: false,
  countryCode: "ci",
  locale: "fr-FR",
  currency: "XOF",
  server,
  cinetpay: {
    cpK: "696297544611a72a3237119.53867449",
    si: 276377,
    cmode: "PRODUCTION",
  },
};
