import { Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { COUNTRY_CODE } from "../app.config";
import { LocalCurrencyPipe } from "../common/pipes/local-currency.pipe";
import { CountryConfig } from "../common/types";

@Injectable({
  providedIn: "root",
})
export class AppService {
  readonly currencyLabel = this.localCurrencyPipe
    .transform("0")
    .replace(/0|,|[.]/g, "");

  constructor(
    private http: HttpClient,
    @Inject(COUNTRY_CODE) readonly countryCode: string,
    private readonly localCurrencyPipe: LocalCurrencyPipe
  ) {}

  getCountry(code?: string): Observable<CountryConfig> {
    return this.http.get<CountryConfig>(
      `/countries/${code || this.countryCode}`
    );
  }
}
