import { Routes } from "@angular/router";

import { startupGuard } from "./common/guards/startup.guard";
import { AuthGuard } from "./common/services/auth.guard";

export const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./pages/public/public.routes"),
  },
  {
    path: "register",
    loadChildren: () => import("./pages/register/register.routes"),
  },
  {
    path: "login",
    loadComponent: () =>
      import("./pages/login/login.page").then((m) => m.LoginPage),
  },
  {
    path: "main",
    loadChildren: () => import("./pages/main/main.routes"),
    canActivate: [AuthGuard],
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/forgot-password/forgot-password.routes"),
  },
  {
    path: "startup",
    loadComponent: () =>
      import("./pages/startup/startup.page").then((m) => m.StartupPage),
    canActivate: [startupGuard],
  },
];
