import { NgxPermissionsModule } from "ngx-permissions";
import { Observable, tap } from "rxjs";
import { environment } from "src/environments/environment";

import { CurrencyPipe, registerLocaleData } from "@angular/common";
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import localeEn from "@angular/common/locales/en";
import localeFr from "@angular/common/locales/fr";
import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  InjectionToken,
  LOCALE_ID,
} from "@angular/core";
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from "@angular/router";
import { JWT_OPTIONS, JwtModule } from "@auth0/angular-jwt";
import { IonicRouteStrategy } from "@ionic/angular";
import { provideIonicAngular } from "@ionic/angular/standalone";
import { Drivers } from "@ionic/storage";
import { IonicStorageModule } from "@ionic/storage-angular";
import { provideEffects } from "@ngrx/effects";
import { provideStore, Store } from "@ngrx/store";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { ApiInterceptor } from "./api/api-interceptor.";
import { routes } from "./app.routes";
import { LocalCurrencyPipe } from "./common/pipes/local-currency.pipe";
import { ACCESS_TOKEN_KEY } from "./common/services";
import { StorageService } from "./common/services/storage/storage.service";
import { AppService } from "./core/app.service";
import { getCountrySuccess } from "./core/state/app.actions";
import { appReducer } from "./core/state/app.state";
import { getMeEffects } from "./core/state/get-me.effects";
import { loginEffects } from "./core/state/login.effects";

registerLocaleData(localeFr);

export const COUNTRY_CODE = new InjectionToken<string>("COUNTRY_CODE");
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export function initializeAppFactory(
  appService: AppService,
  store: Store,
  translateService: TranslateService
) {
  return (): Observable<any> => {
    return appService.getCountry().pipe(
      tap((country) => {
        translateService.use(country.languages[0]);
        store.dispatch(getCountrySuccess({ country }));
      })
    );
  };
}

export function jwtOptionsFactory(storage: StorageService) {
  return {
    tokenGetter: () => {
      return storage.storage?.get(ACCESS_TOKEN_KEY);
    },
    allowedDomains: [environment.server],
    disallowedRoutes: ["http://localhost:3000/assets"],
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: COUNTRY_CODE,
      useValue: environment.countryCode,
    },
    {
      provide: LOCALE_ID,
      useValue: environment.locale,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: environment.currency,
    },
    CurrencyPipe,
    LocalCurrencyPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [AppService, Store, TranslateService],
    },
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([ApiInterceptor])
    ),
    importProvidersFrom(
      JwtModule.forRoot({
        jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useFactory: jwtOptionsFactory,
          deps: [StorageService],
        },
      })
    ),
    provideRouter(
      routes,
      withPreloading(PreloadAllModules), // https://web.dev/articles/route-preloading-in-angular
      withRouterConfig({
        onSameUrlNavigation: "reload",
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: "top",
      })
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideStore({ app: appReducer }),
    provideEffects(getMeEffects, loginEffects),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !environment.production,
      autoPause: true,
    }),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      NgxPermissionsModule.forRoot(),
      IonicStorageModule.forRoot({
        name: "__doonci",
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      })
    ),
  ],
};
