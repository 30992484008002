import { CurrencyPipe } from "@angular/common";
import {
  DEFAULT_CURRENCY_CODE,
  inject,
  LOCALE_ID,
  Pipe,
  PipeTransform,
} from "@angular/core";

@Pipe({
  name: "localCurrency",
  standalone: true,
})
export class LocalCurrencyPipe implements PipeTransform {
  readonly currencyPipe = inject(CurrencyPipe);
  readonly localeId = inject(LOCALE_ID);
  readonly currencyCode = inject(DEFAULT_CURRENCY_CODE);
  transform(value: number | string, ...args: unknown[]): string {
    return this.currencyPipe.transform(
      value,
      this.currencyCode,
      "symbol-narrow",
      undefined,
      this.localeId
    );
  }
}
